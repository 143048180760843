html {
	width: 100%;
}

body {
	width: 100%;
	font-family: Arial, sans-serif !important;
	font-size: 15px;
	line-height: 1.7;
	color: $text-color;
}

/* Heading */
h1, h2, h3, h4, h5, h6 {
	font-weight: 600;
	color: $title-color;
	line-height: 1.2;
	font-family: 'Source Sans Pro', sans-serif !important;
}
.table-title h6{
    color: #212529;
    font-weight: bold;
}

/* header */
header .logo{
    position: relative;
    width: 100%;
    .logo-img{
        margin: 0 auto;
        height : 50px;
    }
}
header .logo .nvm{
    display: none;
}
section.detail-title-bar .bg-light-blue,
section.object-content .openhouse, section.object-content .view-by-appointment{
    background-color: #eeeeee;
}


.aanbod-header-block header .phonenumber{
    display: inline-block;
    text-align: right;
    left: 0px;
    top: 15px;
    right: auto;
    overflow: hidden;
    background-color: $secondary-color;
    padding: 15px 30px;
    &:hover{
        background-color: $primary-color;
    }
    @media (min-width: 992px) {
        left: auto;
        right: 210px;
        top: 15px;
        overflow: auto;
    }
    a{
        color: white;
        font-size: 0rem;
        width :60px;
        height: 54px;
        @media (min-width: 992px) {
            font-size: 1rem;
            height: auto;
            width: auto;
        }
    }
    .fas.fa-phone{
        color: white;
        fill: white;
        font-size: 1rem;
    }
}
.aanbod-header-block header.narrow .phonenumber{
    right: 149px;
}

/* footer */
.footer .container {
    padding: 60px 0;
    background-color:  $title-color;
    color: $color-white;
    h1, h2, h3, h4, h5, h6{
        color: $color-white;
    }
    a{
        color: $color-white;
    }

}
.footer:before{
    background-color: $primary-background-color-50;
}
.bg-blue{
    color: $white;
}


/* detail page */
.detail-header-image .top-detail-right{
    background-color: #005188;
}
.detail-header-image .top-detail-right h1,
.detail-header-image .top-detail-right h2,
.detail-header-image .top-detail-right h3,
.detail-header-image .top-detail-right h4,
.detail-header-image .top-detail-right h5,
.detail-header-image .top-detail-right h6,
.top-detail-left table.table th,
.top-detail-left table.table th h6{
    color: $white;
}

.card__count{
    color: gray;
}
.countdown{
    color: $title-color;
}
.bid-history .table-title h6,
.bid-history{
    color: $title-color;
}
table.table th{
    font-weight: normal;
    color: gray;
}
/* footer */
.aligned{
    &.left-align{
        .logo-left{
            .footer-nvm svg{
                fill: #fff;
            }
            .footer-funda svg{
                fill: #fff;
            }
        }
        .logo-right{
            .footer-nwwi svg{
                fill: #fff;
            }
            .footer-nrvg svg{
                fill: #fff;
            }
        }
    }
}
