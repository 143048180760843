$primary-color: #1FA5FF;
$primary-background-color: #1FA5FF;
$primary-background-color-75: rgba(31, 165, 255, 0.75);
$primary-background-color-50: rgba(31, 165, 255, 0.5);
$primary-background-color-25: #dfd6d1;
$primary-background-color-light: #f8f2ef;
$primary-background-color-dark: #1FA5FF;

$secondary-color: #99CC00;
$secondary-background-color: #99CC00;
$secondary-background-color-75: rgba(153, 204, 0,  0.75);
$secondary-background-color-50: rgba(153, 204, 0, 0.5);
$secondary-background-color-25: rgba(153, 204, 0, 0.35);
$secondary-background-color-light: #f8f2ef;

$color-white: #ffffff;
$color-black: #000000;

$red-color: #e63744;
$red-background-color: #e63744;

$text-color: #333333;
$title-color: #1FA5FF;
$h1-color: #1FA5FF;

// Cookie
$mainCookieBG: $primary-color;
$cookieLinkColor: #fff;
$cookieSlideActiveColor: darken($mainCookieBG, 30%);
$cookieCloseIconColorOnHover: red;
$cookiePrivacyLinkColorOnHover: darken($mainCookieBG, 30%);

$cookieBtnBG: #fff;
